<template>
	<v-dialog v-model="dialog" width="500">
		<template v-slot:activator="{ on, attrs }">
			<v-row>
				<v-col class="text-right" col="12">
					<v-btn color="primary" v-if="!addCustomer.id" dark v-bind="attrs" v-on="on">
						<v-icon left>{{ icons.mdiPlus }}</v-icon
						>Add New
					</v-btn>
				</v-col>
			</v-row>
		</template>
		<v-card>
			<v-card-title>{{ !addCustomer.id ? 'Add New Customer' : 'Edit Customer' }}</v-card-title>
			<v-card-text>
				<v-container>
					<v-row>
						<v-col cols="12">
							<v-form ref="form" v-model="isValid" lazy-validation>
								<v-row>
									<v-col cols="12">
										<label>Company Name</label>
										<v-text-field
											v-model="addCustomer.companyName"
											type="text"
											outlined
											dense
											placeholder="E.g. Star infotech Pvt. Ltd."
											:rules="nameRules"
										></v-text-field>

										<label>Representative Name</label>
										<v-text-field
											v-model="addCustomer.name"
											type="text"
											outlined
											dense
											placeholder="E.g. John Wick"
											:rules="nameRules"
										></v-text-field>
										<label>Representative Email</label>
										<v-text-field
											v-model="addCustomer.email"
											type="email"
											:readonly="addCustomer.id > 0"
											outlined
											dense
											placeholder="E.g. JohnWick@movie.com"
											:rules="emailRules"
										></v-text-field>
										<label>Representative Contact No</label>
										<v-text-field
											v-model="addCustomer.mobileNo"
											type="number"
											outlined
											dense
											:readonly="addCustomer.id > 0"
											placeholder="E.g. 1231231231"
											:rules="mobileNoRules"
										></v-text-field>
									</v-col>
									<v-col offset-md="3" cols="12">
										<v-btn color="primary" v-on:click="saveCustomer" :loading="loading" :disabled="loading">
											Save
										</v-btn>
										&nbsp;&nbsp;
										<v-btn color="light" @click="dialog = false">Cancel</v-btn>
									</v-col>
								</v-row>
							</v-form>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	import { mdiPlus } from '@mdi/js'
	import { ref, reactive } from '@vue/composition-api'
	import Util from '@/utils/axios-util.js'

	export default {
		props: {
			customerObj: {
				default() {
					return {}
				},
				type: Object,
			},
		},
		setup() {
			const addCustomer = reactive({ name: '', email: '', mobileNo: '', companyName: '' })
			const dialog = ref(false)
			const isValid = ref(false)
			const loading = ref(false)

			return {
				dialog,
				addCustomer,
				isValid,
				icons: {
					mdiPlus,
				},
				loading,
				nameRules: [
					v => !!v || 'Name is required.',
					v => (v && v.length <= 50) || 'Name must be less than 50 characters.',
				],
				emailRules: [v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'Please enter valid E-mail.'],
				mobileNoRules: [v => !!v || 'Mobile No is required.', v => /^\d{10}$/.test(v) || 'Please enter valid mobile no.'],
			}
		},
		mounted() {
			let e = this
			if (e.customerObj != {} && e.customerObj.id) {
				e.addCustomer = Object.assign({}, e.customerObj)
				e.dialog = true
			}
		},
		methods: {
			saveCustomer: function () {
				let e = this
				let isValidated = e.$refs.form.validate()
				if (isValidated) {
					e.loading = true
					Util.Axios.post(e.addCustomer.id ? `/customer/${e.addCustomer.id}/update` : '/customer/add-new', e.addCustomer)
						.then(function (response) {
							if (response.data.succeeded) {
								e.successMessage('Customer details saved successfully.')
								e.dialog = false
								e.addCustomer = { name: '', email: '', mobileNo: '', companyName: '' }
								e.$emit('customerDetailsChanged')
							} else {
								e.errorMessage(e.generateErrorMessage(response.errors))
							}
						})
						.catch(function (response) {})
						.finally(function (response) {
							e.loading = false
						})
				}
			},
		},
	}
</script>
