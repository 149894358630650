<template>
	<v-row>
		<v-col cols="12">
			<AddCustomerForm @customerDetailsChanged="updateDtKey" />
		</v-col>
		<v-col cols="12">
			<datatable :key="dataTableKey" @editCustomer="edit"></datatable>
		</v-col>
		<component
			v-bind:is="editComponent"
			@customerDetailsChanged="updateDtKey"
			v-if="editComponent && editObj != {}"
			:customerObj="editObj"
		></component>
	</v-row>
</template>

<script>
	import Util from '@/utils/axios-util.js'
	import Datatable from './Datatable'
	import AddCustomerForm from './AddCustomerForm'

	export default {
		components: {
			Datatable,
			AddCustomerForm,
		},
		setup() {
			const dataTableKey = ''
			const editObj = {}
			const editComponentKey = ''

			return {
				dataTableKey,
				editObj,
				editComponent: '',
				editComponentKey: '',
			}
		},
		methods: {
			updateDtKey: function () {
				let e = this
				e.dataTableKey = e.GenerateRandomKey(4)
				e.editComponent = ''
			},
			edit: function (id) {
				let e = this
				if (!id) {
					e.errorMessage('Invalid customer selected.')
					return false
				}
				e.editComponent = ''
				Util.Axios.get(`/customer/${id}/get-details`)
					.then(function (response) {
						if (response.data.succeeded) {
							e.editObj = response.data.data
							e.editComponent = 'AddCustomerForm'
							e.editComponentKey = e.GenerateRandomKey(5)
						} else e.errorMessage('Something went wrong!')
					})
					.catch(function (response) {})
					.finally(function (response) {})
			},
			async fetchData() {
				let e = this
				if (e.customFilters.auditTrailName) {
					const response = await Util.Axios.get(
						`/Log/get-paged-audittrails?&AuditableEntityCode=${e.customFilters.auditTrailName}`,
					)
					e.ExcelDownload.data = response.data.data.items
					return e.ExcelDownload.data
				} else {
					e.errorMessage('Please select Entity to export Audit Trail.')
					return []
				}
			},
		},
	}
</script>
